import React, { createContext, useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { toast } from 'react-toastify'
import { useWeb3React } from '@web3-react/core'
import { injected } from 'src/connectors'
import PlatformAbi, { Erc20TokenAbi } from 'src/context/abi'
import {
  getContract,
  getWeb3ContractObject,
  getWeb3Obj,
  swichNetworkHandler,
} from 'src/utils'
import {
  networkList,
  getCurrentContractAddress,
  getNetworkDetails,
  getToTokenList,
  RPC_URL_BNB,
  RPC_URL_BNB_TEST,
  withoutTokenPoolContract,
  PoolzAddressTestNetwrok,
  ACTIVE_NETWORK,
  BNB_NETWORK_TEST,
} from 'src/constants'
import useIsMountedRef from 'src/components/useIsMountedRef'
import axios from 'axios'
import apiconfigs from 'src/config/APIConfig'
import withOutTokenDefaultABI from 'src/context/withOutTokenDefaultABI.json'
import withoutTokenPoolABI from 'src/context/withoutTokenPoolABI.json'

export const AuthContext = createContext()

export default function AuthProvider(props) {
  const { activate, chainId, deactivate, library, account } = useWeb3React()
  const [isLogin, setIsLogin] = useState(false)
  const [userData, setUserData] = useState({})
  const [poolList, setPoolList] = useState([])
  const [poolListMyInvestment, setpoolListMyInvestment] = useState([])
  const [poolDataMyPools, setPoolDataMyPools] = useState([])
  const [isAdmin, setIsAdmin] = useState(false)
  const [allPoolData, setAllPoolData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdatingData, setIsUpdatingData] = useState(false)
  const [myInvestmentList, setMyInvestmentList] = useState([])
  const [userPoolList, setUserPoolList] = useState([])
  const [withoutTokenPoolList, setWithoutTokenPoolList] = useState([])
  const [withoutTokenMyPools, setWithoutTokenMyPools] = useState([])
  const [withoutTokenMyInvestment, setWithoutTokenMyInvestment] = useState([])
  const [selectedBlockchain, setSelectedBlockchain] = useState(
    JSON.parse(window.localStorage.getItem('NetworkCheck'))
      ? JSON.parse(window.localStorage.getItem('NetworkCheck'))
      : networkList[0],
  )

  const [selectedNetworkDetails, setSelectedNetworkDetails] = useState()
  const allPoolRef = useIsMountedRef()
  const myInvestmentRef = useIsMountedRef()
  const userPoolRef = useIsMountedRef()
  const [toTokenList, setToTokenList] = useState()
  const [kycList, setKycList] = useState([])
  const [isKycVerified, setIsKycVerified] = useState(false)
  const [imageURL, setImageUrl] = useState('')
  useEffect(() => {
    if (selectedBlockchain.chainId) {
      if (account && chainId != selectedBlockchain.chainId) {
        swichNetworkHandler(selectedBlockchain.chainId)
      }

      setSelectedNetworkDetails(
        getNetworkDetails(selectedBlockchain.chainId)[0],
      )

      setToTokenList(getToTokenList(selectedBlockchain.chainId))
    }
  }, [selectedBlockchain])

  useEffect(() => {
    if (selectedBlockchain.chainId && account) {
      swichNetworkHandler(selectedBlockchain.chainId)
    }
  }, [account, selectedBlockchain])

  const connectWalletWeb3 = () => {
    activate(injected, undefined, true).catch((error) => {
      if (error) {
        toast.error(JSON.stringify(error.message))
        activate(injected)
      }
    })
  }

  const connectWalletAPIHandler = async (account) => {
    setIsKycVerified(false)
    try {
      const res = await axios.post(apiconfigs.connectWallet, {
        walletAddress: account,
      })
      if (res.data.code === 200) {
        setKycList(res.data.data.kycList)
        for (let i = 0; i < res.data.data.kycList.length; i++) {
          let kycData = res.data.data.kycList[i]
          if (
            kycData.walletAddress.toLowerCase() === account.toLowerCase() &&
            kycData.kycStatus === 'VERIFIED'
          ) {
            setIsKycVerified(true)
            break
          }
        }
      }
      // console.log("res", res);
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    if (account) {
      setIsLogin(true)
      connectWalletAPIHandler(account)
    }
  }, [account])

  const disconnectWallte = async () => {
    setUserData({})
    setIsLogin(false)
    setIsAdmin(false)
    deactivate()
    window.location.reload()
  }

  const getTableDataDashboard = async () => {
    setIsUpdatingData(true)
    try {
      const PlatformContractAddress = getCurrentContractAddress(
        selectedBlockchain.chainId,
      )

      const contract = await getWeb3ContractObject(
        PlatformAbi,
        PlatformContractAddress,
        selectedNetworkDetails?.rpcUrls[0],
      )
      setAllPoolData([])
      setIsLoading(true)
      let res = await contract.methods.poolsCount().call()
      // console.log('res', res)
      let resultObj = {}
      for (let i = res - 1; i >= 0; i--) {
        /*jshint loopfunc: true */

        let poolStatus = await contract.methods.GetPoolStatus(i).call()
        let poolExtraData = await contract.methods.GetPoolExtraData(i).call()
        let poolBaseData = await contract.methods.GetPoolBaseData(i).call()
        let poolMoreData = await contract.methods.GetPoolMoreData(i).call()
        resultObj = {
          Token: poolBaseData[0],
          Creator: poolBaseData[1],
          FinishTime: poolBaseData[2],
          Rate: poolBaseData[3],
          StartAmount: poolBaseData[4],
          Lefttokens: poolMoreData[1],
          Maincoin: poolExtraData[1],
          StartTime: poolMoreData[2],
          ImageUrl: poolExtraData[5],
          // bannerURL: bannerURL,
          // isPoolCertified: isPoolCertified,
        }
        const tokenNameContract = await getWeb3ContractObject(
          Erc20TokenAbi,
          poolBaseData[0],
          selectedNetworkDetails.rpcUrls[0],
        )

        let tokenNameFun = await tokenNameContract.methods.name().call()
        if (allPoolRef.current) {
          setAllPoolData((data) => [
            ...data,
            {
              id: i,
              data: resultObj,
              poolStatus: poolStatus,
              tokenName: tokenNameFun,
            },
          ])
        }
      }
      setIsUpdatingData(false)

      setIsLoading(false)
    } catch (error) {
      console.log('ERRROR', error)
      setIsUpdatingData(false)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (
      selectedNetworkDetails &&
      selectedBlockchain &&
      (ACTIVE_NETWORK == selectedBlockchain.chainId ||
        BNB_NETWORK_TEST == selectedBlockchain.chainId)
    ) {
      getTableDataDashboard()
    }
  }, [selectedNetworkDetails, selectedBlockchain, chainId, allPoolRef])

  const getTableDataMyInvestment = async () => {
    // window.localStorage.removeItem("NetworkCheck");
    setMyInvestmentList([])
    try {
      const PlatformContractAddress = getCurrentContractAddress(
        selectedBlockchain.chainId,
      )
      const PlatformWithSigner = getContract(
        PlatformContractAddress,
        PlatformAbi,
        library,
        account,
      )
      let res = await PlatformWithSigner.GetMyInvestmentIds()
      let resultObjMyInvestment = {}
      for (let i = res.length - 1; i >= 0; i--) {
        /*jshint loopfunc: true */
        const getInvestMydata = await PlatformWithSigner.GetInvestmentData(
          parseInt(res[i].toString()),
        )

        let poolBaseData = await PlatformWithSigner.GetPoolBaseData(
          getInvestMydata[0].toString(),
        )
        let poolMoreData = await PlatformWithSigner.GetPoolMoreData(
          getInvestMydata[0].toString(),
        )
        let poolExtraData = await PlatformWithSigner.GetPoolExtraData(
          getInvestMydata[0].toString(),
        )
        let poolStatus = await PlatformWithSigner.GetPoolStatus(
          getInvestMydata[0].toString(),
        )

        resultObjMyInvestment = {
          Token: poolBaseData[0],
          Creator: poolBaseData[1],
          FinishTime: poolBaseData[2],
          Rate: poolBaseData[3],
          StartAmount: poolBaseData[4],
          Lefttokens: poolMoreData[1],
          Maincoin: poolExtraData[2],
          ImageUrl: poolExtraData[5],
        }
        const tokenCOntractWithSigner = getContract(
          poolBaseData[0],
          Erc20TokenAbi,
          library,
          account,
        )

        let tokenNameFun = await tokenCOntractWithSigner.name()
        if (myInvestmentRef.current) {
          setMyInvestmentList((data) => [
            ...data,
            {
              id: getInvestMydata[0].toString(),
              data: resultObjMyInvestment,
              poolStatus: poolStatus,
              tokenName: tokenNameFun,
            },
          ])
        }
        window.localStorage.removeItem('NetworkCheck')
      }
    } catch (error) {
      console.log('ERRROR', error)
    }
  }

  // const getTableDataGetMyPools = async () => {
  //   try {
  //     // window.localStorage.removeItem("NetworkCheck");
  //     setUserPoolList([])
  //     const PlatformContractAddress = getCurrentContractAddress(
  //       selectedBlockchain.chainId,
  //     )

  //     const PlatformWithSigner = getContract(
  //       PlatformContractAddress,
  //       PlatformAbi,
  //       library,
  //       account,
  //     )

  //     let res = await PlatformWithSigner.GetMyPoolsId()
  //     let resultObjMyPools = {}
  //     for (let i = res.length - 1; i >= 0; i--) {
  //       /*jshint loopfunc: true */
  //       let poolBaseData = await PlatformWithSigner.GetPoolBaseData(i)
  //       let poolMoreData = await PlatformWithSigner.GetPoolMoreData(i)
  //       let poolExtraData = await PlatformWithSigner.GetPoolExtraData(i)
  //       let poolStatus = await PlatformWithSigner.GetPoolStatus(i)
  //       // let isPoolCertified = await contract.isPoolCertified(i);
  //       resultObjMyPools = {
  //         Token: poolBaseData[0],
  //         Creator: poolBaseData[1],
  //         FinishTime: poolBaseData[2],
  //         Rate: poolBaseData[3],
  //         StartAmount: poolBaseData[4],
  //         Lefttokens: poolMoreData[1],
  //         Maincoin: poolExtraData[2],
  //         ImageUrl: poolExtraData[5],
  //       }
  //       const tokenCOntractWithSigner = getContract(
  //         poolBaseData[0],
  //         Erc20TokenAbi,
  //         library,
  //         account,
  //       )

  //       let tokenNameFun = await tokenCOntractWithSigner.name()

  //       if (userPoolRef.current) {
  //         setUserPoolList((data) => [
  //           ...data,
  //           {
  //             id: i,
  //             data: resultObjMyPools,
  //             poolStatus: poolStatus,
  //             tokenName: tokenNameFun,
  //           },
  //         ])
  //       }
  //       window.localStorage.removeItem('NetworkCheck')
  //     }
  //   } catch (error) {
  //     console.log('ERROR', error)
  //   }
  // }

  // useEffect(() => {
  //   if (
  //     account &&
  //     selectedNetworkDetails &&
  //     chainId == selectedNetworkDetails.chainId &&
  //     (chainId == ACTIVE_NETWORK || chainId == BNB_NETWORK_TEST)
  //   ) {
  //     // getTableDataMyInvestment();
  //   }
  // }, [account, selectedNetworkDetails, chainId, myInvestmentRef])

  // useEffect(() => {
  //   // console.log('selectedBlockchain', selectedBlockchain)
  //   if (
  //     account &&
  //     selectedNetworkDetails &&
  //     selectedBlockchain &&
  //     (ACTIVE_NETWORK == selectedBlockchain.chainId ||
  //       BNB_NETWORK_TEST == selectedBlockchain.chainId)
  //   ) {
  //     getTableDataGetMyPools()
  //   }
  // }, [account, selectedNetworkDetails, chainId, userPoolRef])

  // WITHOUT TOKEN POOL DETAILS

  // ALL
  const withoutTokenPoolsDetailsHandler = async () => {
    try {
      const web3 = await getWeb3Obj()
      const contract = await getWeb3ContractObject(
        withoutTokenPoolABI,
        '0x54090c26959D0Cf12E3F4406525579Fad25168a7',
        RPC_URL_BNB,
      )
      let res = await contract.methods.poolsCount().call()
      for (let i = 1; i > 0; i--) {
        let poolBaseData = await contract.methods.GetPoolBaseData(i).call()
        let poolMoreData = await contract.methods.GetPoolMoreData(i).call()
        let resultObj = {
          description: web3.utils.hexToAscii(poolBaseData[0]),
          name: poolBaseData[1],
          chain: poolBaseData[2],
          FinishTime: poolBaseData[3],
          hardCapInWei: web3.utils.fromWei(poolMoreData[0]),
          StartTime: poolMoreData[1],
          totalCollectedToken: web3.utils.fromWei(poolMoreData[2]),
        }
        setWithoutTokenPoolList((data) => [
          ...data,
          {
            id: i,
            data: resultObj,
          },
        ])
      }
    } catch (error) {}
  }
  const [poolsWithoutTokenTestNet, setWithoutTokenPoolListTestNet] = useState(
    [],
  )
  const [loadingWihtoutTokens, setLoadingWihtoutTokenPools] = useState(false)
  const withoutTokenPoolsDetailsHandlerTestNet = async () => {
    try {
      setLoadingWihtoutTokenPools(true)
      const web3 = await getWeb3Obj()
      const contract = await getWeb3ContractObject(
        withOutTokenDefaultABI,
        PoolzAddressTestNetwrok,
        RPC_URL_BNB,
      )
      let res = await contract.methods.poolsCount().call()
      for (let i = res - 1; i >= 0; i--) {
        if (i === 1) {
          continue
        }
        let poolBaseData = await contract.methods.GetPoolBaseData(i).call()
        let poolMoreData = await contract.methods.GetPoolMoreData(i).call()

        let resultObj = {
          description: poolBaseData[0],
          name: poolBaseData[1],
          chain: poolBaseData[2],
          imageURL: poolBaseData[4],
          FinishTime: poolBaseData[3],
          hardCapInWei: web3.utils.fromWei(poolMoreData[0]),
          StartTime: poolMoreData[1],
          totalCollectedToken: web3.utils.fromWei(poolMoreData[2]),
        }

        setWithoutTokenPoolListTestNet((data) => [
          ...data,
          {
            id: i,
            data: resultObj,
          },
        ])
        setLoadingWihtoutTokenPools(false)
      }
      setLoadingWihtoutTokenPools(false)
    } catch (error) {
      setLoadingWihtoutTokenPools(false)
    }
  }

  useEffect(() => {
    withoutTokenPoolsDetailsHandler()
    withoutTokenPoolsDetailsHandlerTestNet()
  }, [])

  // MY POOL
  const withoutTokenMyPoolsDetailsHandler = async () => {
    try {
      const web3 = await getWeb3Obj()
      const contract = await getWeb3ContractObject(
        withoutTokenPoolABI,
        withoutTokenPoolContract,
        RPC_URL_BNB,
      )

      let res = await contract.methods.GetMyPoolsId().call({
        from: account,
      })
      // console.log('res', res)

      for (let i = res.length - 1; i > 0; i--) {
        let poolBaseData = await contract.methods.GetPoolBaseData(i).call()
        let poolMoreData = await contract.methods.GetPoolMoreData(i).call()
        // console.log('poolMoreData', poolMoreData)

        let resultObj = {
          description: web3.utils.hexToAscii(poolBaseData[0]),
          name: poolBaseData[1],
          chain: poolBaseData[2],
          FinishTime: poolBaseData[3],
          hardCapInWei: web3.utils.fromWei(poolMoreData[0]),
          StartTime: poolMoreData[1],
          totalCollectedToken: web3.utils.fromWei(poolMoreData[2]),
        }
        // console.log('withoutTokenMyPoolsDetailsHandler', resultObj)

        setWithoutTokenMyPools((data) => [
          ...data,
          {
            id: i,
            data: resultObj,
          },
        ])
      }
    } catch (error) {
      console.log('ERROR', error)
    }
  }
  useEffect(() => {
    if (account) {
      withoutTokenMyPoolsDetailsHandler()
    }

    // }
  }, [account])

  // My Investment
  const withoutTokenMyInvestmentPoolsDetailsHandler = async () => {
    try {
      const web3 = await getWeb3Obj()
      const contract = await getWeb3ContractObject(
        withoutTokenPoolABI,
        withoutTokenPoolContract,
        RPC_URL_BNB,
      )

      let res = await contract.methods.GetMyInvestmentIds().call({
        from: account,
      })
      // console.log('res', res)

      for (let i = res.length - 1; i > 0; i--) {
        let poolBaseData = await contract.methods.GetPoolBaseData(i).call()
        let poolMoreData = await contract.methods.GetPoolMoreData(i).call()

        let resultObj = {
          description: web3.utils.hexToAscii(poolBaseData[0]),
          name: poolBaseData[1],
          chain: poolBaseData[2],
          FinishTime: poolBaseData[3],
          hardCapInWei: web3.utils.fromWei(poolMoreData[0]),
          StartTime: poolMoreData[1],
          totalCollectedToken: web3.utils.fromWei(poolMoreData[2]),
        }
        setWithoutTokenMyInvestment((data) => [
          ...data,
          {
            id: i,
            data: resultObj,
          },
        ])
      }
    } catch (error) {
      console.log('ERROR', error)
    }
  }
  // useEffect(() => {
  //   if (account) {
  //     // withoutTokenMyInvestmentPoolsDetailsHandler();
  //   }
  // }, [account, chainId])

  let data = {
    userLoggedIn: isLogin,
    selectedBlockchain,
    toTokenList,
    selectedNetworkDetails,
    userData,
    isAdmin,
    allPoolData,
    isLoading,
    isUpdatingData,
    myInvestmentList,
    userPoolList,
    kycList,
    isKycVerified,
    withoutTokenPoolList,
    withoutTokenMyPools,
    withoutTokenMyInvestment,
    poolsWithoutTokenTestNet,
    loadingWihtoutTokens,
    setSelectedBlockchain: (data) => setSelectedBlockchain(data),
    getTableDataDashboard: () => getTableDataDashboard(),
    setPoolListData: (data) => setPoolList(data),
    setPoolListDataMyInvestment: (data) => setpoolListMyInvestment(data),
    setPoolListDataMyPools: (data) => setPoolList(data),
    setPoolDataMyPools: (data) => setPoolDataMyPools(data),
    connectWalletAPIHandler: (account) => connectWalletAPIHandler(account),
    getTableDataMyInvestment: () => getTableDataMyInvestment(),
    withoutTokenPoolsDetailsHandler: () => withoutTokenPoolsDetailsHandler(),
    withoutTokenMyPoolsDetailsHandler: (account) =>
      withoutTokenMyPoolsDetailsHandler(account),
    withoutTokenMyInvestmentPoolsDetailsHandler: (account) =>
      withoutTokenMyInvestmentPoolsDetailsHandler(account),
    poolList,
    poolListMyInvestment,
    poolDataMyPools,
    connectToWallet: () => connectWalletWeb3(),
    disconnectWallte: () => disconnectWallte(),
    userLogIn: (type, data) => {
      // setSession(data);
      setIsLogin(type)
    },
  }

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  )
}
