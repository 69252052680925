import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    // MuiOutlinedInput: {
    //   root: {
    //     "&:hover": {
    //       notchedOutline: {
    //         borderColor: " #18181800",
    //       },
    //     },
    //   },
    // },

    MuiOutlinedInput: {
      notchedOutline: {
        // borderColor: " #18181800",
        borderRadius: 50,
        // "&:hover": {
        //   borderColor: " #18181800",
        // },
      },
    },
    MuiTab: {
      wrapper: {
        fontWeight: 600,
        fontSize: "15px",
        lineHeight: "25px",
        padding: "0 20px",
        textAlign: "center",
        color: "#fff",
        fontFamily: "'Montserrat', sans-serif",
        "@media(max-width:991px)": {
          fontSize: "14px",
        },
      },
    },

    MuiStepContent: {
      root: {
        marginLeft: 281,
        marginTop: 0,
        paddingLeft: 250,
        paddingRight: 300,
      },
    },

    MuiStepLabel: {
      iconContainer: {
        order: 2,
        paddingLeft: 30,
      },
      labelContainer: {
        width: 226,
        textAlign: "right",
      },
    },

    MuiStepConnector: {
      vertical: {
        marginLeft: 281,
        padding: 0,
      },
      lineVertical: {
        borderLeftWidth: "1px",
      },
      "&$active": {
        borderColor: "#00e0b0",
      },
    },

    MuiStepIcon: {
      backgroundColor: "white",

      completed: {
        backgroundColor: "#edf5f3",
        border: "none",
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
        // color: "#14bbf5",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },
    MuiSelectOutlined: {
      MuiSelectOutlined: {
        "&:focus": {
          backgroundColor: "yellow",
          outline: "none",
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: "50px",
      },
      contained: {
        boxShadow: "none",

        "&:hover": {
          boxShadow: "none",
        },
      },
      outlined: {
        boxShadow: "none",
        borderWidth: 1,
      },
      outlinedPrimary: {
        boxShadow: "none",
      },
      containedSizeLarge: {
        color: "#ffffff",
        padding: "14px 30px",
      },
    },
    MuiFilledInput: {
      underline: {
        "&::before": {
          borderBottom: 0,
        },
        "&::after": {
          borderBottom: 0,
        },
        "&:hover": {
          "&::before": {
            borderBottom: 0,
          },
        },
      },
      input: {
        padding: "12px 12px 10px",
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#14bbf5",
    },
    background: {
      default: "#efefee",
      dark: "#000000",
      light: "#191a1b",
      paper: colors.common.white,
    },
    primary: {
      main: "#14bbf5",
      dark: "#1194c1",
      light: "#f1cab1",
    },
    secondary: {
      main: "#000",
      dark: "#222",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#000000",
      secondary: "#6b6b6b",
    },
    common: {
      black: "#242526",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
