import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'
import DashboardLayout from 'src/layouts/DashboardLayout'
import HomeLayout from 'src/layouts/HomeLayout'

export const routes = [
  {
    exact: true,
    path: '/',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Home')),
  },
  {
    exact: true,
    path: '/create',
    layout: DashboardLayout,
    component: lazy(() => import('src/layouts/DashboardLayout/CreatePool')),
  },
  {
    exact: true,
    path: '/create-withoutToken',
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/layouts/DashboardLayout/CreateWithoutToken'),
    ),
  },
  // {
  //   exact: true,
  //   path: "/create-pool",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/layouts/DashboardLayout/Create")),
  // },
  {
    exact: true,
    path: '/about',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/About')),
  },
  {
    exact: true,
    path: '/privacy-policy',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/PrivacyPolicy')),
  },
  {
    exact: true,
    path: '/dashboard',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Dashboard')),
  },

  {
    exact: true,
    path: '/my-investments',
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/MyInvestments')),
  },
  {
    exact: true,
    path: '/created-pools',
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/CreatedPools')),
  },
  {
    exact: true,
    path: '/pools-withoutToken',
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/PoolsWithoutTokens'),
    ),
  },
  {
    exact: true,
    path: '/admin',
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Admin/AdminOnly')),
  },
  {
    exact: true,
    path: '/projects',
    layout: DashboardLayout,
    component: lazy(() => import('src/Projects/Details')),
  },
  {
    exact: true,
    path: '/projects-withoutToken',
    layout: DashboardLayout,
    component: lazy(() => import('src/Projects/WithoutTokenDetails')),
  },
  {
    exact: true,
    path: '/projects-withoutTokenTest',
    layout: DashboardLayout,
    component: lazy(() => import('src/Projects/WithoutTokenDetailsTestNet')),
  },
  {
    exact: true,
    path: '/projects-new',
    layout: DashboardLayout,
    component: lazy(() => import('src/views/ProjectData/Details')),
  },
  {
    exact: true,
    path: '/how-it-work',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/HowItWorks')),
  },
  {
    exact: true,
    path: '/kyc',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/KYC/KycIndex')),
  },
  {
    exact: true,
    path: '/add-kyc',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/KYC/index')),
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFound')),
  },
  {
    component: () => <Redirect to="/404" />,
  },
]
