import React from "react";
import { Box, IconButton } from "@material-ui/core";
import { Facebook, Twitter, LinkedIn, Telegram } from "@material-ui/icons";
export default function SocialLinks(props) {
  function myFunction(url) {
    window.open(url);
  }
  return (
    <Box display="flex" justifyContent="center" color="#ffffff">
      <IconButton
        color="inherit"
        onClick={() => myFunction("https://t.me/GainPoolcommunity")}
      >
        <Telegram />
      </IconButton>

      <IconButton
        color="inherit"
        onClick={() => myFunction("https://www.linkedin.com/company/gainpool/")}
      >
        <LinkedIn />
      </IconButton>

      <IconButton
        color="inherit"
        onClick={() => myFunction("https://twitter.com/GainPool")}
      >
        <Twitter />
      </IconButton>
    </Box>
  );
}
