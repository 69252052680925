// import React, { useState } from 'react';
// import {
//   Grid,
//   Box,
//   Container,
//   Typography,
//   Link,
//   makeStyles,
//   TextField,
//   Button,
// } from '@material-ui/core';
// // import CallIcon from '@material-ui/icons/Call';
// import MailOutlineIcon from '@material-ui/icons/MailOutline';
// // import LocationOnIcon from '@material-ui/icons/LocationOn';
// import Logo from 'src/components/Logo';
// import SocialLinks from 'src/components/SocialLinks';
// import { Link as RouterLink } from 'react-router-dom';
// import { LinkedIn, Telegram, Facebook, Twitter } from '@material-ui/icons';
// import axios from 'axios';
// import Alert from '@material-ui/lab/Alert';
// import ButtonCircularProgress from 'src/components/ButtonCircularProgress';

// function validateEmail(email) {
//   const re =
//     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   return re.test(String(email).toLowerCase());
// }

// const useStyles = makeStyles((theme) => ({
//   root: {
//     background: theme.palette.common.black,
//     color: '#ffffff',
//     '& a': {
//       color: '#ffffff',
//     },
//   },
//   icon: {
//     display: 'inline-flex',
//     verticalAlign: 'top',
//     marginRight: 10,
//   },
//   footerBar: {
//     background: theme.palette.background.dark,
//     color: '#ffffff',
//   },
// }));
// export default function Liquidity() {
//   const classes = useStyles();
//   const [email, setEmail] = useState('');
//   const [submitMsg, setSubmitMsg] = useState('');
//   const [isSubmit, setIsSubmit] = useState(false);
//   const [isUpdating, setIsUpdating] = useState(false);
//   const subscribeHandler = async () => {
//     setIsSubmit(true);
//     if (email !== '' && validateEmail(email)) {
//       setIsUpdating(true);
//       try {
//         const response = await axios.post(
//           'http://182.72.203.245:1825/api/v1/contact/subscribe',
//           {
//             email: email,
//           }
//         );
//         if (response.data.responseCode === 200) {
//           setSubmitMsg(response.data.responseMessage);
//         } else {
//           setSubmitMsg(response.data.responseMessage);
//         }
//         setIsUpdating(false);
//         setTimeout(function () {
//           setSubmitMsg('');
//           setEmail('');
//         }, 3000);
//         setIsSubmit(false);
//       } catch (err) {
//         setIsUpdating(false);
//         console.error('ERROR', err);
//       }
//     }
//   };

//   return (
//     <>
//       <Box className={classes.root} py={5}>
//         <Container maxWidth="lg">
//           <Box>
//             <Grid container spacing={2}>
//               <Grid item xs={12} md={4}>
//                 <Logo width="180" />
//                 <Typography
//                   style={{ marginTop: 20 }}
//                   color="inherit"
//                   variant="body2"
//                 >
//                   Enter your email id below to subscribe and receive information
//                   about our latest offerings and other important updates
//                 </Typography>
//                 <Box mt={3} display="flex" pr={2}>
//                   <TextField
//                     fullWidth
//                     placeholder="Your email"
//                     name="email"
//                     onChange={(e) => setEmail(e.target.value)}
//                     type="text"
//                     value={email}
//                     variant="filled"
//                     style={{
//                       background: '#ffffff',
//                     }}
//                   />
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={subscribeHandler}
//                     disabled={isUpdating}
//                   >
//                     Subscribe {isUpdating && <ButtonCircularProgress />}
//                   </Button>
//                 </Box>
//                 {submitMsg !== '' && (
//                   <Box py={2} pr={2}>
//                     <Alert severity="success">{submitMsg}</Alert>
//                   </Box>
//                 )}
//                 {isSubmit && (email === '' || !validateEmail(email)) && (
//                   <Box py={2} pr={2}>
//                     <Alert severity="error">{'Enter Valid Email'}</Alert>
//                   </Box>
//                 )}
//               </Grid>
//               <Grid item xs={12} md={2}>
//                 <Typography variant="h5" color="inherit">
//                   Resources
//                 </Typography>
//                 <Box mt={2}>
//                   <Link to="/" component={RouterLink}>
//                     Whitepaper
//                   </Link>
//                 </Box>
//                 <Box mt={2}>
//                   <Link to="/privacy-policy" component={RouterLink}>
//                     Privacy Policy
//                   </Link>
//                 </Box>
//                 <Box mt={2}>
//                   <Link to="/how-it-work" component={RouterLink}>
//                     How it works
//                   </Link>
//                 </Box>
//               </Grid>
//               <Grid item xs={12} md={2}>
//                 <Typography variant="h5" color="inherit">
//                   Company
//                 </Typography>
//                 <Box mt={2}>
//                   <Link to="/" component={RouterLink}>
//                     Contact us
//                   </Link>
//                 </Box>
//                 <Box mt={2}>
//                   <Link to="/" component={RouterLink}>
//                     Get tokens
//                   </Link>
//                 </Box>
//               </Grid>
//               <Grid item xs={12} md={4}>
//                 <Typography variant="h5" color="inherit">
//                   Get In Touch
//                 </Typography>
//                 <Box mt={2}>
//                   <MailOutlineIcon className={classes.icon} fontSize="small" />
//                   {/* <Link to="mailto:hellow@xyz.com" component={RouterLink}> */}
//                   contact@admantium.finance
//                   {/* </Link> */}
//                 </Box>
//                 <Box mt={2}>
//                   <Telegram className={classes.icon} fontSize="small" />
//                   <Link
//                     href="https://t.me/admantiumfinancechat"
//                     target="_blank"
//                   >
//                     Telegram
//                   </Link>
//                 </Box>
//                 <Box mt={2}>
//                   <Facebook className={classes.icon} fontSize="small" />
//                   <Link
//                     href="https://www.facebook.com/admantiumcryptoadvisors"
//                     target="_blank"
//                   >
//                     Facebook
//                   </Link>
//                 </Box>
//                 <Box mt={2}>
//                   <LinkedIn className={classes.icon} fontSize="small" />
//                   <Link
//                     href="https://www.linkedin.com/company/admantium/"
//                     target="_blank"
//                   >
//                     LinkedIn
//                   </Link>
//                 </Box>
//                 <Box mt={2}>
//                   <Twitter className={classes.icon} fontSize="small" />
//                   <Link
//                     href="https://twitter.com/admantiumcrypto"
//                     target="_blank"
//                   >
//                     Twitter
//                   </Link>
//                 </Box>
//               </Grid>
//             </Grid>
//           </Box>
//         </Container>
//       </Box>
//       <Box className={classes.footerBar} py={1}>
//         <Container maxWidth="lg">
//           <Box display="flex" alignItems="center" className="mobile-block">
//             <Typography color="inherit" variant="body2" className="text-center">
//               © 2021. All Rights Reserved.
//             </Typography>
//             <Box flexGrow={1} />
//             <SocialLinks />
//           </Box>
//         </Container>
//       </Box>
//     </>
//   );
// }
import React from "react";
import {
  Grid,
  Box,
  Link,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { AiOutlineMedium } from "react-icons/ai";
import { AiFillGithub } from "react-icons/ai";
import { BsTwitter } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import {} from "react-feather";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    background: "#231F20",
    position: "relative",
    // paddingTop: "50px ",
    backgroundPosition: " bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
    },
    // "&"
    "& h5": {
      fontSize: "16px",
      letterSpacing: "2px",
      color: "#FFFFFF",
    },
    "& ul": {
      paddingLeft: "0",
    },
    "& p": {
      fontSize: "12px",
      color: "#858585",
      margin: "0",
      padding: "10px 0",
    },
  },
  footerBg: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    left: "0",
  },
  ListItem: {
    paddingLeft: "0px",
  },
  borderBottmo: {
    overflow: "hidden",
    background: "#000",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  signupBtn: {
    color: "#fff",
    display: "flex",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    minWidth: "100px",
    borderRadius: "50px",
    position: "absolute",
    top: "5px",
    right: "5px",
    boxShadow:
      "0px 8px 24px rgba(38, 50, 56, 0.1), 0px 16px 32px rgba(38, 50, 56, 0.08)",
    lineHeight: "36px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: " 1px",
    background: "#040405",
    "&:hover": {
      background: "#040405",
      color: "#fff",
    },
  },

  socialicon: {
    display: "flex",
    "& a": {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      // backgroundColor: "#00bdff",
      color: "#fff",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "20px",
      border: "1px solid #fff",
      marginRight: "9px",
      display: "flex",

      "&:hover": {
        border: "1px solid #14bbf5;",
        color: "#14bbf5;",
      },
    },
  },

  largeIcon: {
    width: 18,
    height: 18,
    marginRight: "8px",
  },
  icons: {
    justify: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justify: "center",
    },
  },
  inputBox: {
    position: "relative",
  },
  footerBgImg: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    zIndex: "1",
  },
  textFild: {
    position: "relative",

    "& button": {
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      backgroundColor: "#000",
      minWidth: "90px",
      fontSize: "18px",
      fontWeight: "700",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#000",
      },
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  return (
    <Box className={classes.footerSection}>
      <Container maxWidth="lg">
        <Grid
          style={{ borderBottom: "1px solid gray" }}
          container
          justify="space-around"
          spacing={1}
        >
          <Grid item item xs={12} sm={6} md={3}>
            <img alt="" src="images/logo.png" />
          </Grid>

          <Grid item item xs={6} sm={6} md={3} align="left">
            <Box class=" col-lg-4 col-md-4 col-sm-6 col-12">
              <Typography variant="h5">Document</Typography>

              <ul className="list-unstyled footer_text">
                <li>
                  <a
                    href="https://docsend.com/view/88c9x443pgvj9e39"
                    target="_blank"
                  >
                    White Paper
                  </a>
                </li>
                <li>
                  <a
                    href="https://docsend.com/view/4aw47iriw5usevrh"
                    target="_blank"
                  >
                    One Pager
                  </a>
                </li>
                <li>
                  <a
                    href="https://docsend.com/view/5di9r66ns3vw2jep"
                    target="_blank"
                  >
                    Pitch Deck
                  </a>
                </li>
                <li>
                  <a href="https://github.com/GainPool" target="_blank">
                    Github
                  </a>
                </li>
              </ul>
            </Box>
          </Grid>

          <Grid item item xs={6} sm={6} md={3} align="left">
            <Box class=" col-lg-4 col-md-4 col-sm-6 col-12">
              <Typography variant="h5">General</Typography>

              <ul class="list-unstyled footer_text">
                <li>
                  <a href="/about">About Us</a>
                </li>
                <li>
                  <a
                    style={{ cursor: "pointer" }}
                    // onClick={() => {
                    //   document.getElementById("Features").scrollIntoView();
                    // }}
                    href="https://gainpool.io/features"
                    target="_blank"
                  >
                    Features
                  </a>
                </li>
                <li>
                  <a
                    style={{ cursor: "pointer" }}
                    // onClick={() => {
                    //   document.getElementById("Roadmap").scrollIntoView();
                    // }}
                    href="https://gainpool.io/roadmap"
                    target="_blank"
                  >
                    Roadmap
                  </a>
                </li>
                {/* <li>
                  <a href="/launchpad-service">Services</a>
                </li> */}
              </ul>
            </Box>
          </Grid>

          <Grid item item xs={12} sm={6} md={3} align="left">
            <Typography variant="h5">Find us on Social Media</Typography>
            <Box align="left" mt={3} mb={3} className="follow">
              <a href="https://medium.com/gainpool" target="_blank">
                <AiOutlineMedium />
              </a>
              <a href="https://twitter.com/GainPool" target="_blank">
                <BsTwitter />
              </a>
              <a
                href="https://www.linkedin.com/company/gainpool/"
                target="_blank"
              >
                <FaLinkedinIn />
              </a>
              <a href="https://github.com/GainPool" target="_blank">
                <AiFillGithub />
              </a>
              <a href="https://t.me/GainPoolcommunity" target="_blank">
                <FaTelegramPlane />
              </a>
            </Box>
            <ul className="list-unstyled footer_text">
              <li>
                <a
                  target="_blank"
                  href="https://gainpool.io/brand-assets"
                  target="_blank"
                >
                  Brand Assets
                </a>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>
      <p align="center">
        @ 2021 GainPool{" "}
        <a
          target="_blank"
          style={{ color: "inherit" }}
          href="https://gainpool.io/terms-of-use"
        >
          Terms of Use{" "}
        </a>{" "}
        and{" "}
        <a
          target="_blank"
          style={{ color: "inherit" }}
          href="https://gainpool.io/privacy-policy"
        >
          Privacy Policy
        </a>
      </p>
    </Box>
  );
}
