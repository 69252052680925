import React from 'react';
import { useHistory } from 'react-router-dom';
const Logo = (props) => {
  const history = useHistory();
  return (
    <img
      onClick={() => history.push('/')}
      style={{ cursor: 'pointer' }}
      src="/images/logo.png"
      alt="Logo"
      {...props}
    />
  );
};

export default Logo;
