import React from "react";
import { makeStyles } from "@material-ui/core";
import Footer from "./Footer";
import TopBar from "src/layouts/HomeLayout/TopBar";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({ once: true });

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    paddingTop: 80,
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TopBar />
      <div className={classes.content}>{children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;
