import { networkList } from "src/constants";
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  DialogActions,
  Button,
} from "@material-ui/core";
import React, { useContext } from "react";
import { AuthContext } from "src/context/Auth";

export default function SelectBlockChainPopup({ open, handleClose }) {
  const auth = useContext(AuthContext);
  return (
    <>
      {open && (
        <Dialog
          fullWidth="sm"
          maxWidth="sm"
          open={open}
          onClose={() => handleClose(false)}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogContent>
            <Typography variant="h4">Select Blockchain</Typography>
            <Box display="flex" alignItems="center" flexDirection="column">
              {networkList.map((data, i) => {
                return (
                  <Box
                    key={i}
                    textAlign="center"
                    mt={2}
                    style={{ width: "70%" }}
                  >
                    <Button
                      variant="contained"
                      color={
                        auth.selectedBlockchain.name === data.name
                          ? "primary"
                          : ""
                      }
                      fullWidth
                      onClick={() => {
                        auth.setSelectedBlockchain(data);
                        setTimeout(() => {
                          handleClose();
                        }, 500);
                        window.localStorage.setItem(
                          "NetworkCheck",
                          JSON.stringify(data)
                        );
                      }}
                    >
                      {data.name}
                    </Button>{" "}
                  </Box>
                );
              })}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
