import React, { useState, useContext, useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Hidden,
  Box,
  Typography,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'
import { Menu } from 'react-feather'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import Logo from 'src/components/Logo'
import CreatePool from 'src/layouts/DashboardLayout/CreatePool'
import { AuthContext } from 'src/context/Auth'
import { Copy } from 'react-feather'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import PlatformAbi, { vestingAddress, Erc20TokenAbi } from 'src/context/abi'
import VestingABI from 'src/context/VestingABI.json'
import TostMsg, { copyTextHandler } from 'src/components/TostMsg'
import { useHistory } from 'react-router'
import { ethers } from 'ethers'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useWeb3React } from '@web3-react/core'
import { getContract, getWeb3ContractObject } from 'src/utils'
import SelectBlockChainPopup from 'src/components/SelectBlockChainPopup'
import {
  withoutTokenPoolContract,
  PoolzAddressTestNetwrok,
  BNB_NETWORK,
  BNB_NETWORK_TEST,
  getCurrentContractAddress,
} from 'src/constants'
import withoutTokenPoolABI from 'src/context/withoutTokenPoolABI.json'
import withOutTokenDefaultABI from 'src/context/withOutTokenDefaultABI.json'
import { ChildCare } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
  },
  toolbar: {
    height: 80,
    padding: '0 10px',
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  modalBox: {
    backgroundColor: '#fff',
    borderRadius: '16px',
    padding: '20px',
    color: '#000',
    width: 'fit-content',
    minWidth: '300px',
    position: 'absolute',
    top: '36%',
    left: '43%',
  },
  mainBox: {
    position: 'relative',
  },
  dataField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '& h4': {
      fontSize: '16px',
      fontWeight: '400',
    },
    '& h5': {
      fontSize: '16px',
      fontWeight: '400',
      color: 'grey',
    },
  },
}))

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles()
  const { account, library, chainId } = useWeb3React()
  const auth = useContext(AuthContext)
  const [open, setOpen] = useState(false)
  const history = useHistory()
  const [adminChecku, setAdminCheck] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [userDetailsList, setUserDetailsList] = useState([])
  const [claimed, setClaimed] = useState(false)
  const [openSelectBlockchain, setOpenSelectBlockchain] = useState(false)
  const [withoutTokenAdmin, setwithoutTokenAdmin] = useState('')

  const adminCheckFun = async () => {
    const VestingcontractWithSigner = getContract(
      vestingAddress,
      VestingABI,
      library,
      account,
    )

    const adminCheckOwner = await VestingcontractWithSigner.owner()
    setAdminCheck(adminCheckOwner)

    const userDetailsFun = await VestingcontractWithSigner.userInfo(account)
    // const cliamedFun = await VestingcontractWithSigner.claimed(account);

    // setClaimed(cliamedFun);
    setUserDetailsList(userDetailsFun)
  }
  const [owner, setOwner] = useState('')
  const adminCheckhandler = async () => {
    try {
      const PlatformContractAddress = getCurrentContractAddress(
        auth?.selectedBlockchain?.chainId,
      )

      const contract = await getWeb3ContractObject(
        PlatformAbi,
        PlatformContractAddress,
        auth?.selectedNetworkDetails?.rpcUrls[0],
      )
      const owner = await contract.methods.owner().call()
      setOwner(owner)
    } catch (err) {
      console.log(err)
    }
  }

  const setwithoutTokenAdminHandler = async () => {
    const vestingContract = new ethers.Contract(vestingAddress, VestingABI)

    const VestingcontractWithSigner = getContract(
      PoolzAddressTestNetwrok,
      withOutTokenDefaultABI,
      library,
      account,
    )

    const adminCheckOwner = await VestingcontractWithSigner.owner()
    setwithoutTokenAdmin(adminCheckOwner)
  }

  const claimTokensHandler = async () => {
    const currentTimeStamp = new Date()
    try {
      // const vestingContract = new ethers.Contract(vestingAddress, VestingABI);
      const VestingcontractWithSigner = getContract(
        vestingAddress,
        VestingABI,
        library,
        account,
      )
      // vestingContract.connect(
      //   auth.userData.signer
      // );
      // const cliamedFun = await VestingcontractWithSigner.claimed(account);

      // if (!cliamedFun) {
      if (ethers.utils.formatEther(userDetailsList.allocatedAmount) > '0.0') {
        if (
          moment(new Date(parseInt(userDetailsList.claimTime) * 1000)).format(
            'YYYY-MM-DD HH:mm',
          ) <= moment(currentTimeStamp).format('YYYY-MM-DD HH:mm')
        ) {
          const claimTokensFun = await VestingcontractWithSigner.claimToken()
          // console.log('claimTokensFun', claimTokensFun)
        } else {
          toast.error('claim time has not reached')
        }
      } else {
        toast.error('claim amount is insufficient')
      }
      // } else {
      //   toast.error("Already claimed");
      // }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (account && chainId == BNB_NETWORK) {
      setwithoutTokenAdminHandler()
    }
    // else if (account && chainId == BNB_NETWORK_TEST) {
    //   setwithoutTokenAdminHandler()
    // }
    else {
      setwithoutTokenAdmin()
    }
  }, [account, chainId])

  useEffect(() => {
    if (account && chainId == BNB_NETWORK_TEST) {
      adminCheckFun()
    } else {
      setAdminCheck()
    }
    if (account && chainId == BNB_NETWORK) {
      adminCheckhandler()
    }
  }, [account, chainId])

  return (
    <Box className={classes.mainBox}>
      {openSelectBlockchain && (
        <SelectBlockChainPopup
          open={openSelectBlockchain}
          handleClose={() => setOpenSelectBlockchain(false)}
        />
      )}
      <AppBar
        className={clsx(classes.root, className)}
        color="default"
        {...rest}
      >
        {/* <TostMsg msg="Copied..." /> */}
        <Toolbar className={classes.toolbar}>
          <Logo width="100" />

          <Box flexGrow={1} display="flex" marginLeft="10%">
            <Hidden xsDown>
              <Typography style={{ color: 'white' }}>
                {/* {auth.userData.balance} */}
              </Typography>
              <Typography style={{ color: 'white' }}>
                <span id="address" style={{ display: 'none' }}>
                  {account && account}
                </span>
                {account &&
                  /* `${account}`} */
                  `${account.slice(0, 4)}...${account.slice(-4)}`}
                &nbsp;&nbsp;
                {account && (
                  // <Copy
                  //   size="16"
                  //   color="gray"
                  //   onClick={() => copyTextHandler("address")}
                  // />
                  <CopyToClipboard text={account}>
                    <IconButton
                      style={{ color: '#fff' }}
                      onClick={() => toast.success('Copied Successfully')}
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </CopyToClipboard>
                )}
              </Typography>
            </Hidden>
          </Box>
          {open && <CreatePool close={() => setOpen(false)} open={open} />}
          <Hidden xsDown>
            <Box>
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: 10 }}
                onClick={() => setOpenSelectBlockchain(true)}
              >
                Select Blockchain
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: 10 }}
                onClick={() => {
                  if (auth.isKycVerified) {
                    window.open(
                      'https://docs.google.com/forms/d/e/1FAIpQLSey6RSlPN-VyIKurOa9bDCR6rgzTXh_2SdTmtak0i9QupAIlw/viewform',
                      '_blank',
                    )
                  } else {
                    toast.warn('Please do KYC verification!!')
                  }
                }}
              >
                Apply For IDO
              </Button>
              {owner === account && (
                <Button
                  variant="contained"
                  color="primary"
                  // onClick={() => setOpen(true)}
                  onClick={() => history.push('/create')}
                  style={
                    !auth.userLoggedIn
                      ? {
                          color: 'white',
                          border: '1px solid red',
                          marginRight: 10,
                        }
                      : { marginRight: 10 }
                  }
                  disabled={!auth.userLoggedIn}
                >
                  Create Pool
                </Button>
              )}
              {withoutTokenAdmin == account && (
                <Button
                  variant="contained"
                  color="primary"
                  // onClick={() => setOpen(true)}
                  onClick={() => history.push('/create-withoutToken')}
                  style={
                    !auth.userLoggedIn
                      ? {
                          color: 'white',
                          border: '1px solid red',
                          marginRight: 10,
                        }
                      : { marginRight: 10 }
                  }
                  disabled={!auth.userLoggedIn}
                >
                  Create Pool Without Token
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                // onClick={() => setOpen(true)}
                onClick={() => history.push('/kyc')}
                style={
                  !auth.userLoggedIn
                    ? {
                        color: 'white',
                        border: '1px solid red',
                        marginRight: 10,
                      }
                    : { marginRight: 10 }
                }
                disabled={!auth.userLoggedIn}
              >
                KYC
              </Button>
              {adminChecku === account && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => history.push('/admin')}
                >
                  Vest
                </Button>
              )}

              {adminChecku !== account && (
                <Button
                  variant="contained"
                  color="primary"
                  style={
                    !auth.userLoggedIn
                      ? {
                          color: 'white',
                          border: '1px solid red',
                          marginRight: 10,
                        }
                      : { marginRight: 10 }
                  }
                  disabled={!auth.userLoggedIn}
                  onClick={() => setModalOpen(true)}
                >
                  Claim Tokens
                </Button>
              )}

              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  auth.userLoggedIn
                    ? auth.disconnectWallte()
                    : auth.connectToWallet()
                }
                style={
                  auth.userLoggedIn
                    ? { color: 'white', border: '1px solid red' }
                    : {}
                }
                // disabled={auth.userLoggedIn}
              >
                {auth.userLoggedIn ? 'Disconnect' : 'Connect'} Wallet
              </Button>
            </Box>
          </Hidden>
          <Hidden lgUp>
            <IconButton
              color="inherit"
              onClick={onMobileNavOpen}
              style={{ marginRight: 10 }}
            >
              <Menu color="#ffffff" />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box className={classes.modalBox}>
          <Box className={classes.dataField}>
            <Typography variant="h4">Your token</Typography>
            <Typography variant="h5">
              {account && (
                <>
                  {userDetailsList.allocatedAmount
                    ? ethers.utils.formatEther(userDetailsList.allocatedAmount)
                    : '00'}
                </>
              )}
            </Typography>
          </Box>
          <Box className={classes.dataField}>
            <Typography variant="h4">Time</Typography>
            <Typography variant="h5">
              {account && (
                <>
                  {userDetailsList.claimTime
                    ? moment(
                        new Date(parseInt(userDetailsList.claimTime) * 1000),
                      ).format('YYYY-MM-DD HH:mm')
                    : '0-0-00'}{' '}
                </>
              )}
            </Typography>
          </Box>
          {/* <Box className={classes.dataField}>
            <Typography variant="h4">Claimed</Typography>
            <Typography variant="h5">{claimed ? "Yes" : "No"}</Typography>
          </Box> */}
          <Box className={classes.dataField}>
            <Typography variant="h4">Type</Typography>
            <Typography variant="h5">
              {account && (
                <>
                  {userDetailsList.durationType == 0
                    ? 'Airdrop (3 months)'
                    : userDetailsList.durationType == 1
                    ? 'Advisor (6 months)'
                    : userDetailsList.durationType == 2
                    ? 'Team (12 months)'
                    : userDetailsList.durationType == 3
                    ? 'Reserve (12 months)'
                    : ''}
                </>
              )}
            </Typography>
          </Box>
          <Button
            fullWidth
            style={{
              backgroundColor: '#14bbf5',
              color: '#fff',
              marginTop: '13px',
            }}
            onClick={claimTokensHandler}
          >
            Claim Tokens
          </Button>
        </Box>
      </Modal>
    </Box>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
}
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
}

export default TopBar
