import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  Button,
  Hidden,
  Container,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import SwipeableTemporaryDrawer from "./RightDrawer";
import { PieChart as PieChartIcon, Info as InfoIcon } from "react-feather";
import Logo from "src/components/Logo";
import { Link as RouterLink, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
  },
  toolbar: {
    height: 80,
    color: "#ffffff",
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightBold,
    "&": {
      marginRight: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const sections = [
  {
    items: [
      {
        title: "Home",
        icon: PieChartIcon,
        href: "/",
      },
      {
        title: "About",
        href: "/about",
        icon: InfoIcon,
      },
      // {
      //   title: "KYC",
      //   href: "/kyc",
      // },
    ],
  },
];

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Container maxWidth="lg">
        <Toolbar className={classes.toolbar}>
          <TopBarData />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData(props) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Logo width="100" />
      <Box flexGrow={1} />
      <Hidden smDown>
        {sections[0].items.map((item, index) => {
          return (
            <Link
              key={`nav${index}`}
              className={classes.link}
              // component={Link}
              to={item.href}
              underline="none"
              variant="body2"
              color={
                history.location.pathname !== item.href ? "inherit" : "primary"
              }
              style={{ textDecoration: "none", color: "#fff" }}
            >
              {item.title}
            </Link>
          );
        })}
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/dashboard"
        >
          Enter Application
        </Button>
      </Hidden>
      <Hidden mdUp>
        <SwipeableTemporaryDrawer sections={sections} />
      </Hidden>
    </>
  );
}
