import React, { useContext, useState, useEffect, useRef } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import {
  AppBar,
  Container,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Box,
  Slide,
  Dialog,
  Typography,
  Step,
  Stepper,
  StepLabel,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core'
import { AuthContext } from 'src/context/Auth'
import PlatformAbi, { Erc20TokenAbi } from 'src/context/abi'
import { ethers } from 'ethers'
import { DateTimePicker } from '@material-ui/pickers'
import ButtonCircularProgress from 'src/components/ButtonCircularProgress'
import moment from 'moment'
import Alert from '@material-ui/lab/Alert'
import { toast } from 'react-toastify'
import { useWeb3React } from '@web3-react/core'
import { getContract, swichNetworkHandler, getWeb3Obj } from 'src/utils'
import { getCurrentContractAddress } from 'src/constants'
import { useHistory } from 'react-router'
import JoditEditor from 'jodit-react'
import axios from 'axios'
import apiconfigs from 'src/config/APIConfig'
export const getBase64 = (file, cb) => {
  let reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function () {
    cb(reader.result)
  }
  reader.onerror = function (err) {
    console.log('Error: ', err)
  }
}
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    backgroundImage: 'url(images/pool.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: 'contain',
  },
  SelectField: {
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
      background: '#fff',
      paddingRight: '5px',
    },
  },
}))

function getSteps() {
  return ['1. Token information', '2. Swaps Rule', '3. Confirm']
}

export function GetStepContent({
  stepIndex,
  handleBack,
  handleNext,
  formDataRef,
  saveStepOneData,
  saveStepTwoData,
  saveStepThreeData,
  createPoolHandler,
  setImgFileBase,
  imgFileBase,
}) {
  const classes = useStyles()
  const steps = getSteps()
  const { account, library, chainId } = useWeb3React()
  const auth = useContext(AuthContext)
  const [isSubmit, setIsSubmit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [selectedDate, handleDateChange] = useState(
    new Date(formDataRef.pullRunningTime),
  )
  const editor = useRef(null)
  const [imgFileBase64, setImgFileBase64] = useState('')

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/{
  }
  const [formData, setFormData] = React.useState({
    tokenAddress: formDataRef.tokenAddress,
    tokenName: formDataRef.tokenName,
    tokenSymbol: formDataRef.tokenSymbol,
    tokenDecimal: formDataRef.tokenDecimal,
    toToken: formDataRef.toToken,
    tokenValue: formDataRef.tokenValue,
    balance: formDataRef.balance,
    pullRunningTime: formDataRef.pullRunningTime,
    maxLiquidityETH: formDataRef.maxLiquidityETH,
    releaseTimeForFirstInterval: formDataRef.releaseTimeForFirstInterval,
    releaseTimeForSecondInterval: formDataRef.releaseTimeForSecondInterval,
    releasePercentageForFirstInterval:
      formDataRef.releasePercentageForFirstInterval,
    releasePercentageForSecondInterval:
      formDataRef.releasePercentageForSecondInterval,
    description: '',
    isKYC: '',
  })

  const _onInputChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    const temp = { ...formData, [name]: value }

    setFormData(temp)
  }

  const _onInputDateChange = (value, name) => {
    // console.log('value', value)
    // console.log('name', name)
    const temp = { ...formData, [name]: new Date(value) }
    setFormData(temp)
  }

  const approveToken = async () => {
    if (auth.selectedBlockchain.chainId == chainId) {
      try {
        setIsLoading(true)

        const daiWithSigner = getContract(
          formData.tokenAddress,
          Erc20TokenAbi,
          library,
          account,
        )
        // console.log('daiWithSigner=======>>>>', daiWithSigner)
        const PlatformContractAddress = getCurrentContractAddress(
          auth.selectedBlockchain.chainId,
        )
        // console.log('PlatformContractAddress', PlatformContractAddress)
        const amt =
          parseFloat(formData.balance) * parseFloat(formData.tokenValue)
        // console.log('amt', amt)

        // const allowanceCheck = daiWithSigner.allowance(
        //   PlatformContractAddress,
        //   account
        // );

        // console.log("allowanceCheck--->>>", allowanceCheck);

        let tx = await daiWithSigner.approve(
          PlatformContractAddress,
          ethers.utils.parseEther(amt.toString()),
        ) //amountof token
        await tx.wait()
        handleNext()
        saveStepTwoData(formData, selectedDate)

        setIsLoading(false)
        setIsError(true)
      } catch (err) {
        toast.error(err.message)
        console.error('ERROR: ', err)
        setIsLoading(false)
      }
    } else {
      swichNetworkHandler(auth.selectedBlockchain.chainId)
    }
  }

  const nextHandler = async () => {
    if (auth.isKycVerified) {
      setIsError(false)
      setIsSubmit(true)
      if (stepIndex === 0) {
        if (
          formData.tokenAddress !== '' &&
          formData.toToken !== '' &&
          formData.description !== ''
          // formData.releasePercentageForFirstInterval !== "" &&
          // formData.releasePercentageForSecondInterval !== ""
        ) {
          setIsSubmit(false)
          handleNext()
          saveStepOneData(formData)
        }
      }
      if (stepIndex === 1) {
        if (formData.tokenValue !== '' && formData.balance !== '') {
          if (account) {
            setIsSubmit(false)
            await approveToken()
          } else {
            toast.warn('Connect your wallet')
          }
        }
      }
      if (stepIndex === 2) {
        if (formData.maxLiquidityETH !== '' && formData.isKYC !== '') {
          setIsSubmit(false)
          handleNext()
          saveStepThreeData(formData)
        }
      }
      if (stepIndex === steps.length - 1) {
        setIsLoading(true)
        await createPoolHandler(formData)
        // clearFormData();
        // setIsLoading(false);
      }
    } else {
      toast.warn('Please do KYC verification!!')
    }
  }

  const tokenDetailsHandler = async () => {
    // console.log('tokenADDRESS', formData.tokenAddress)
    if (formData.tokenAddress !== '') {
      try {
        // const contractObj = new ethers.Contract(
        //   formData.tokenAddress,
        //   Erc20TokenAbi,
        //   auth.userData.provider
        // );
        const objWithSigner = getContract(
          formData.tokenAddress,
          Erc20TokenAbi,
          library,
          account,
        )

        //await contractObj.connect(auth.userData.signer);
        // console.log('contract---', objWithSigner)
      } catch (err) {
        console.log(err)
      }
    }
  }

  switch (stepIndex) {
    case 0:
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Token Contract Address"
              margin="normal"
              variant="outlined"
              fullWidth
              name="tokenAddress"
              value={formData.tokenAddress}
              onChange={_onInputChange}
              error={isSubmit && formData.tokenAddress === ''}
              helperText={
                isSubmit &&
                formData.tokenAddress === '' &&
                'Please Enter Token Contract Address'
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                To Token
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="To Token"
                defaultValue="ETH"
                name="toToken"
                // value={formData.toToken.}
                onChange={_onInputChange}
                error={isSubmit && formData.toToken === ''}
              >
                {auth.toTokenList &&
                  auth.toTokenList.map((data, i) => {
                    return (
                      <MenuItem key={i} value={data}>
                        {data.name}
                      </MenuItem>
                    )
                  })}
              </Select>
              <FormHelperText error>
                {isSubmit &&
                  formData.toToken === '' &&
                  'Please Select To Token'}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid style={{ color: '#222' }} item sm={12} md={12}>
            <Typography>Description</Typography>
            <JoditEditor
              ref={editor}
              value={formData.description}
              config={config}
              tabIndex={1} // tabIndex of textarea
              style={{ color: '#222' }}
              onBlur={(newContent) => {
                const temp = { ...formData, description: newContent }
                setFormData(temp)
              }} // preferred to use only this option to update the content for performance reasons
              onChange={(newContent) => {
                // console.log("newContent", newContent);
              }}
            />
            {isSubmit && formData.description === '' && (
              <FormHelperText error>Please enter description</FormHelperText>
            )}
          </Grid>

          <Grid
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Box>
              {account ? (
                <Box mt={5}>
                  <Button disabled={true} onClick={handleBack}>
                    Back
                  </Button>
                  {chainId != auth.selectedBlockchain.chainId ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        swichNetworkHandler(auth.selectedBlockchain.chainId)
                      }
                    >
                      Switch network to {auth.selectedBlockchain.name} chain
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={nextHandler}
                    >
                      {stepIndex === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  )}
                </Box>
              ) : (
                <Box mt={5}>
                  {' '}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={auth.connectToWallet}
                  >
                    Connect Wallet
                  </Button>{' '}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      )
    case 1:
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Box className="height100 d-flex">
              <Typography variant="h2">
                1 {formData.toToken && formData.toToken.name} =
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              type="number"
              label="Token Value"
              margin="normal"
              variant="outlined"
              fullWidth
              name="tokenValue"
              value={formData.tokenValue}
              onChange={_onInputChange}
              error={isSubmit && formData.tokenValue === ''}
              helperText={
                isSubmit &&
                formData.tokenValue === '' &&
                'Please Enter Token Value'
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="number"
              label="Amount of tokens you want to lock"
              // placeholder="Amount of tokens you want to lock"
              margin="normal"
              variant="outlined"
              fullWidth
              name="balance"
              value={formData.balance}
              onChange={_onInputChange}
              error={isSubmit && formData.balance === ''}
              helperText={
                isSubmit && formData.balance === '' && 'Please Enter Amount'
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <DateTimePicker
                label="Pool Running Time"
                inputVariant="outlined"
                value={selectedDate}
                onChange={handleDateChange}
                fullWidth
                name="campaignDate"
                disablePast
              />
            </FormControl>
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              label="Release Percentage for first interval"
              margin="normal"
              variant="outlined"
              fullWidth
              name="releasePercentageForFirstInterval"
              type="number"
              value={formData.releasePercentageForFirstInterval}
              onChange={_onInputChange}
              error={
                isSubmit && formData.releasePercentageForFirstInterval === ''
              }
              helperText={
                isSubmit &&
                formData.releasePercentageForFirstInterval === '' &&
                'Please Enter Release Percentage for first interval'
              }
            />
          </Grid>

          <Grid item sm={12} md={6}>
            <TextField
              type="number"
              label="Release Percentage for second interval"
              margin="normal"
              variant="outlined"
              fullWidth
              name="releasePercentageForSecondInterval"
              value={formData.releasePercentageForSecondInterval}
              onChange={_onInputChange}
              error={
                isSubmit && formData.releasePercentageForSecondInterval === ''
              }
              helperText={
                isSubmit &&
                formData.releasePercentageForSecondInterval === '' &&
                'Please Enter Release Percentage for second interval'
              }
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <DateTimePicker
                label="Release time for first interval"
                inputVariant="outlined"
                value={formData.releaseTimeForFirstInterval}
                onChange={(date) =>
                  _onInputDateChange(date, 'releaseTimeForFirstInterval')
                }
                fullWidth
                name="campaignDate"
                disablePast
                helperText="relaese time should be more than pool running time*"
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <DateTimePicker
                label="Release time for second interval"
                inputVariant="outlined"
                value={formData.releaseTimeForSecondInterval}
                onChange={(date) =>
                  _onInputDateChange(date, 'releaseTimeForSecondInterval')
                }
                fullWidth
                name="campaignDate"
                disablePast
              />
            </FormControl>
          </Grid>

          {isError && (
            <Box py={2}>
              <Alert severity="error">{'Error In Transaction'}</Alert>
            </Box>
          )}
          <Grid>
            <Box>
              <Box mt={5}>
                <Button onClick={handleBack} disabled={isLoading}>
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  onClick={nextHandler}
                >
                  {stepIndex === steps.length - 1 ? 'Finish' : 'Next'}
                  {isLoading && <ButtonCircularProgress />}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )
    case 2:
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              type="number"
              label="Max liquidity"
              placeholder="1"
              margin="normal"
              variant="outlined"
              fullWidth
              readonly
              name="maxLiquidityETH"
              value={
                formData.balance && formData.tokenValue
                  ? parseFloat(formData.balance) *
                    parseFloat(formData.tokenValue)
                  : 0
              }
              onChange={_onInputChange}
              disabled
              // error={isSubmit && formData.maxLiquidityETH === ''}
              // helperText={isSubmit && formData.maxLiquidityETH === '' && "Please Enter Max Liquidity ETH"}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              className={classes.SelectField}
              fullWidth
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Is KYC Require
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Is KYC"
                defaultValue="Yes"
                name="isKYC"
                value={formData.isKYC}
                onChange={_onInputChange}
                error={isSubmit && formData.isKYC === ''}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
              <FormHelperText error>
                {isSubmit && formData.isKYC === '' && 'Please Select'}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              className={classes.SelectField}
              fullWidth
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Upload Icon
              </InputLabel>

              {imgFileBase64 ? (
                <Box
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <img src={imgFileBase64 ? imgFileBase64 : ''} alt="" />
                  {/* <label htmlFor="raised-button-file-img">
                    <Button
                      variant="contained"
                      color="secondary"
                      component="span"
                    >
                      Edit File
                    </Button>
                  </label> */}
                </Box>
              ) : (
                <Box className="uploadBox">
                  <Typography variant="h6" style={{ color: '#000' }}>
                    JPG, PNG, GIF, WEBP. Max 20mb.
                  </Typography>

                  <Typography variant="body2" style={{ color: '#000' }}>
                    (620 x 620 recommended)
                  </Typography>
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="raised-button-file-img"
                    multiple
                    type="file"
                    accept=".jpg,.mp3,.png,.gif,.webp"
                    onChange={(e) => {
                      setImgFileBase(e.target.files[0])
                      getBase64(e.target.files[0], (result) => {
                        setImgFileBase64(result)
                      })
                    }}
                  />

                  <label htmlFor="raised-button-file-img">
                    <Button
                      variant="contained"
                      color="secondary"
                      component="span"
                    >
                      Choose File
                    </Button>
                  </label>
                </Box>
              )}

              <FormHelperText error>
                {/* {isSubmit && formData.isKYC === "" && "Please Select"} */}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid>
            <Box>
              <Box mt={5}>
                <Button onClick={handleBack} disabled={isLoading}>
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={nextHandler}
                  disabled={isLoading}
                >
                  {stepIndex === steps.length - 1 ? 'Finish' : 'Next'}
                  {isLoading && <ButtonCircularProgress />}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )
    default:
      return 'Unknown stepIndex'
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function CreatePool({ close, open }) {
  const classes = useStyles()
  const { account, library, chainId } = useWeb3React()
  const [activeStep, setActiveStep] = React.useState(0)
  const steps = getSteps()
  const auth = useContext(AuthContext)
  const history = useHistory()
  const [imgFileBase, setImgFileBase] = useState('')
  const [formData, setFormData] = React.useState({
    tokenAddress: '',
    tokenName: '',
    tokenSymbol: '',
    tokenDecimal: '',
    toToken: '',
    tokenValue: '',
    balance: '',
    pullRunningTime: new Date(),
    maxLiquidityETH: '',
    releaseTimeForFirstInterval: new Date(),
    releaseTimeForSecondInterval: new Date(),
    releasePercentageForFirstInterval: '',
    releasePercentageForSecondInterval: '',
    isKYC: '',
    description: '',
  })

  const saveStepOneData = (data) => {
    const temp = { ...formData }
    setFormData({
      ...temp,
      tokenAddress: data.tokenAddress,
      toToken: data.toToken,
      description: data.description,
    })
  }

  const saveStepTwoData = (data, selectedDate) => {
    const temp = { ...formData }
    // console.log('data.balance', data?.balance)
    setFormData({
      ...temp,
      tokenValue: data.tokenValue,
      balance: data.balance,
      pullRunningTime: selectedDate,
      tokenName: data.tokenName,
      tokenSymbol: data.tokenSymbol,
      tokenDecimal: data.tokenDecimal,
      releaseTimeForFirstInterval: data.releaseTimeForFirstInterval,
      releaseTimeForSecondInterval: data.releaseTimeForSecondInterval,
      releasePercentageForFirstInterval: data.releasePercentageForFirstInterval,
      releasePercentageForSecondInterval:
        data.releasePercentageForSecondInterval,
    })
  }

  const saveStepThreeData = (data) => {
    // console.log('data', data)
    const temp = { ...formData }
    setFormData({
      ...temp,
      maxLiquidityETH: data.maxLiquidityETH,
      isKYC: data.isKYC,
    })
  }

  const createPoolHandler = async (data) => {
    if (auth.selectedBlockchain.chainId == chainId) {
      try {
        const PlatformContractAddress = getCurrentContractAddress(
          auth.selectedBlockchain.chainId,
        )
        // console.log('PlatformContractAddress', PlatformContractAddress)

        const web3 = await getWeb3Obj()
        const PlatformWithSigner = getContract(
          PlatformContractAddress,
          PlatformAbi,
          library,
          account,
        )
        // console.log('PlatformWithSigner', PlatformWithSigner)
        // console.log('formData', formData)
        const amt =
          parseFloat(formData.balance) * parseFloat(formData.tokenValue)

        const perArr = [
          parseFloat(formData.releasePercentageForFirstInterval),
          parseFloat(formData.releasePercentageForSecondInterval),
        ]
        const timeArr = [
          moment(formData.releaseTimeForFirstInterval).unix() -
            moment(formData.pullRunningTime).unix(),
          moment(formData.releaseTimeForSecondInterval).unix() -
            moment(formData.pullRunningTime).unix(),
        ]
        const MaxDuration = await PlatformWithSigner.MaxDuration()
        const MinDuration = await PlatformWithSigner.MinDuration()
        // console.log('MaxDuration', MaxDuration.toString())
        // console.log('MinDuration', MinDuration.toString())
        const formDataa = new FormData()
        formDataa.append('file', imgFileBase)
        await axios({
          method: 'POST',
          url: apiconfigs.addImage,
          data: formDataa,
        }).then(async (res) => {
          if (res.status === 200) {
            if (res?.data?.data?.imageUrl) {
              if (
                moment().unix() + parseFloat(MinDuration.toString()) <=
                moment(formData.pullRunningTime).unix()
              ) {
                if (
                  moment(formData.pullRunningTime).unix() <
                  parseFloat(MaxDuration.toString()) + moment().unix()
                ) {
                  let tx = await PlatformWithSigner.CreatePool(
                    data.tokenAddress,
                    moment(formData.pullRunningTime).unix(),
                    parseInt(formData.tokenValue),
                    ethers.utils.parseEther(amt.toString()),
                    // moment(formData.pullRunningTime).unix(),
                    formData.toToken.address,
                    +new Date(
                      moment().seconds(0).milliseconds(0).toISOString(),
                    ) / 1000,
                    perArr,
                    timeArr,
                    // web3.utils.toHex(formData.description),
                    formData.description.toString(),
                    data.isKYC,
                    res?.data?.data?.imageUrl,
                  )
                  await tx.wait()
                  toast.success('Pool created')
                  setActiveStep(0)
                  clearFormData()
                  auth.getTableDataDashboard()
                  history.push('/dashboard')
                } else {
                  toast.error(`Pool duration can't be that long`)
                }
              }
            } else {
              toast.error(`Need more than MinDuration`)
            }
          }
        })
      } catch (err) {
        toast.error(err.message)
        console.log('ERROR', err)
      }
    } else {
      swichNetworkHandler(auth.selectedBlockchain.chainId)
    }
  }

  const clearFormData = () => {
    setFormData({
      tokenAddress: '',
      tokenName: '',
      tokenSymbol: '',
      tokenDecimal: '',
      toToken: '',
      tokenValue: '',
      balance: '',
      pullRunningTime: new Date(),
      maxLiquidityETH: '',
      description: '',
      isKYC: '',
    })
  }

  const handleNext = (data) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  return (
    <Box
      // maxWidth="sm"
      style={{
        backgroundColor: '#ffffff',
        // padding: "50px 0px",
        padding: '100px',
      }}
    >
      <Container>
        <Stepper>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed
              </Typography>
              <Button onClick={handleReset} variant="contained" color="primary">
                Reset
              </Button>
            </div>
          ) : (
            <Box>
              {/* {getStepContent(activeStep, handleBack, handleNext, formData)} */}
              <GetStepContent
                stepIndex={activeStep}
                handleBack={handleBack}
                handleNext={handleNext}
                formDataRef={formData}
                saveStepOneData={(data) => saveStepOneData(data)}
                saveStepTwoData={(data, selectedDate) =>
                  saveStepTwoData(data, selectedDate)
                }
                saveStepThreeData={(data) => saveStepThreeData(data)}
                createPoolHandler={(data) => createPoolHandler(data)}
                setImgFileBase={(data) => setImgFileBase(data)}
                imgFileBase={imgFileBase}
              />
              {/* <Box mt={5}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </Box> */}
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  )
}
